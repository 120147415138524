$default-green: #2bd67b;
$secondary-green: #1d9756;
$default-background-red: #4d0014;
$secondary-background-red: #3c0010;
$primary-red: #ff0144;
$secondary-red: #cc0136;
$greyish-blue: #435475;
$default-pink: #ff799d;
$light-pink: #ffd7e1;
$default-background-white: #ffffff;

.banner {
    // margin-top: 90px;
    text-align: center;
    line-height: 30px;
    display: flex;
    letter-spacing: 0px;
    color: #002e8c;
    opacity: 1;
    font-weight: 500;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 67px;
    background: #f4f6fe;
    z-index: 101;
    .txt {
        margin: auto 2px;
        font-size: 1.1em;
    }
    img {
        width: 30px;
        height: 30px;
        margin: auto 0;
    }
    #bnb {
        width: 27px;

        margin: auto 5px auto 8px;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
}
.marketplaceContainer {
    // padding: 30px 2vw;
    font-family: Poppins;
    min-height: 96vh;
    padding-top: 174px;
    padding-bottom: 30px;
    @media screen and (max-width: 600px) {
        // padding: 30px 4vw;
        padding-top: 126px;
        min-height: inherit;
    }
    .margin {
        margin-top: 110px;
        @media screen and (max-width: 600px) {
            margin-top: 83px;
        }
    }
    .running_low {
        margin-bottom: 1vh;
        color: #691aaa;
        font-weight: 600;
        text-align: center;
        font-size: 19px;
        /* text-transform: uppercase; */
        font-family: "Poppins";
        a {
            color: rgb(105, 26, 170);
            text-decoration: underline;
        }
        @media screen and (max-width: 600px) {
            font-size: 15px;
        }
    }
    .middlediv {
        display: flex;
        justify-content: space-between;
        // margin-top: 130px;
        // background-color: #f1f3fc;
        margin-bottom: 30px;
        padding: 0 2vw;
        //
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
        @media screen and (max-width: 600px) {
            // margin-top: 76px;
        }

        .section102 {
            height: 90%;
        }
    }
    .section101 {
        width: 100vw;
        padding: 0 2vw;
        display: flex;
        justify-content: space-around;
        margin-bottom: 2rem;
    }
    .section102 {
        display: flex;
        flex-direction: column;
        height: 100%;

        ul {
            margin-bottom: 0 !important;
        }
    }
    .btnss {
        display: flex;
        // margin-bottom: 10px;
        .btn1 {
            position: relative;
            .pseudo {
                width: 100%;
                height: 82px;
                background: #002e8c;
                position: absolute;

                border-radius: 30px 30px 0 0;
            }
            .pseudo:after {
                content: "";
                position: absolute;
                right: -18px;
                bottom: 0;
                width: 53px;
                height: 36px;
                background: #002e8c;
            }
        }
        .bTn:not(:first-child) {
            margin-left: 10px;
        }
        .btn1:after {
            content: "";
            position: absolute;
            right: -40px;
            bottom: 0px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #f1f3fc;
        }
        .btn2 {
            position: relative;
            margin-left: 10px;
            .pseudo {
                width: 100%;
                height: 82px;
                background: #691aaa;
                position: absolute;

                border-radius: 30px 30px 0 0;
            }
            .pseudo:after {
                content: "";
                position: absolute;
                right: -18px;
                bottom: 0;
                width: 53px;
                height: 36px;
                background: #691aaa;
            }
            .pseudo:before {
                content: "";
                position: absolute;
                left: -18px;
                bottom: 0;
                width: 53px;
                height: 36px;
                background: #691aaa;
            }
        }
        .btn2:before {
            content: "";
            position: absolute;
            left: -40px;
            bottom: 0px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #f1f3fc;
            z-index: 1;
        }
        .btn2:after {
            content: "";
            position: absolute;
            right: -40px;
            bottom: 0px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #f1f3fc;
        }
    }

    .belowdiv {
        display: flex;
        justify-content: space-between;
        padding: 0 2vw;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .ant-input {
        background-color: transparent;
    }
    .MuiLinearProgress-barColorPrimary {
        background-color: #002e8c;
    }
}
