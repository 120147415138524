$background-red: #009ee4;
$default-green: #ffffff;
.dropp {
    color: white !important;
    a {
        font-size: 12px;
        font-weight: 600;
        color: white;
        letter-spacing: 2px;
        padding: 15px 15px !important;
        text-transform: uppercase;
    }
    a:hover {
        background-color: #7d002014 !important;
    }
    li::before {
        content: "" !important;
        width: 0 !important;
    }
}

.mainDiv {
    margin-top: 109px;
    z-index: 100;
    width: 100%;

    position: fixed;
    @media screen and (max-width: 600px) {
        margin-top: 70px;
    }
}
.notice {
    text-align: center;
    background-color: #002e8c;
    color: white;
    padding: 10px 0;
    text-align: center;
    background-color: #002e8c;
    color: white;
    padding: 10px 0;
    font-size: 15px;
    font-family: Poppins;
}
.subNavbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.2em 3em;
    color: #c9e8ff;
    text-transform: uppercase;
    font-size: 16px;
    background: $background-red;
    letter-spacing: 3px;
    box-shadow: 0 3px 40px #00000080;
    font-weight: 600;
    font-family: "Poppins";
    height: 65.2px;
    // position: fixed;
    @media screen and (max-width: 1400px) {
        justify-content: space-between;
        align-items: center;
        padding: 1.2em 1em;
        overflow-x: scroll;

        font-size: 12px;
    }
    @media screen and (max-width: 600px) {
        // margin-top: 70px;
        height: 56.2px;
    }
    .s {
        @media screen and (min-width: 601px) {
            display: none;
        }
    }
    .snackbar {
        background-color: white !important;
        color: #080711ba;
        position: fixed;
        top: 20px;
        left: 45%;
        letter-spacing: normal;
        padding: 10px 38px;
        font-family: "Poppins";
        font-weight: 500;
        border-radius: 4px;
        text-transform: initial;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
    img {
        margin: 0 5px;
    }
    .MuiMenu-list,
    .MuiMenu-paper,
    ul,
    li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        top: 65px;
        left: 2%;
        z-index: 10;
        font-size: 12px;
        color: #fff;
        background-color: $background-red !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        animation: fadeIn 0.2s linear;
    }
    & > p,
    & > div,
    & > button,
    & > a {
        cursor: pointer;
        color: white;
        margin-bottom: 0 !important;
        min-width: max-content;
        font-weight: 600;
        letter-spacing: 3px;
        margin-right: 80px;
        &[data-style-attr="selected"] {
            color: $default-green;
        }
        &:hover {
            color: white;
        }
    }
    & > a:hover {
        color: white;
    }

    .marketplaceDropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        top: 65px;
        left: 2%;
        z-index: 10;
        font-size: 12px;
        color: #fff;
        background: $background-red;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        animation: fadeIn 0.2s linear;

        & > span {
            padding: 1em;
            width: 185px;
        }

        & > span:hover {
            background: #7d002014;
        }

        & > span:not(:last-child) {
            border-bottom: 1px solid #7d00201f;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
    & > p:not(:last-child) {
        margin-right: 5em;
    }
    & > a:not(:last-child) {
        margin-right: 5em;
    }
    .address {
        color: white;
        font-weight: 600;
        margin-right: 20px !important;
        & > span {
            color: #c9e8ff;
        }
    }
    .flex1 {
        flex: 1;
    }
    .eachlink {
        display: flex;
        align-items: center;
        outline: none;
        background: transparent;
        border: none;
        margin: 0;
        @media screen and (max-width: 1400px) {
            margin-right: 20px;
        }
    }

    .l {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
}
.centered {
    @media screen and (max-width: 600px) {
        // justify-content: center;
    }
}
