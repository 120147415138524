$default-green: #2bd67b;
$secondary-green: #1d9756;
$default-background-red: #4d0014;
$secondary-background-red: #3c0010;
$primary-red: #ff0144;
$secondary-red: #cc0136;
$greyish-blue: #435475;
$default-pink: #ff799d;
$light-pink: #ffd7e1;
$default-background-white: #f1f3fc;

.marketplaceContainer {
    background-color: $default-background-white;
    // padding: 30px 2.6vw;
    padding-top: 174px;
    @media screen and (max-width: 600px) {
        // padding: 30px 4vw;
        padding-top: 126px;
    }
    .middlediv {
        display: flex;
        justify-content: space-between;
        margin-top: 55px;
        margin-bottom: 30px;
        padding: 0 2vw;
        margin-top: 130px;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }
        @media screen and (max-width: 600px) {
            margin-top: 75px;
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 25vw;
        @media screen and (max-width: 1024px) {
            width: 100%;
            flex-direction: row;
            margin-top: 30px;
            display: none;
        }
        // @media screen and (max-width: 520px) {
        //   width: 100%;
        //   flex-direction: column;
        //   margin-top: 30px;
        // }
    }
    .boxx {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 40px #7090b026;
        border-radius: 20px;
        opacity: 1;
        display: flex;
        display: flex;
        height: 47%;
        padding: 10px;
        @media screen and (max-width: 520px) {
            margin-bottom: 10px;
        }
        .imgg {
            background: #f0f2fc 0% 0% no-repeat padding-box;
            border-radius: 20px;
            opacity: 0.4;
            display: flex;
            padding: 0 30px;
        }
        .side_box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 30px;
            margin: auto;
            & > span:nth-child(1) {
                text-align: left;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0px;
                color: #7689b0;
                opacity: 1;
            }
            & > span:nth-child(2) {
                text-align: left;
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0px;
                color: #009ee4;
                opacity: 1;
            }
        }
    }

    .belowdiv {
        display: flex;
        padding: 0 2vw 2vw;

        justify-content: space-between;
    }
}
